/** Styles added to angular.json */

@use 'apps/lk-egk/src/variables' as var;

.btn {
  --btn-disable-opacity: 0.5;
  --btn-font-size: 16px;
  --btn-text-transform: uppercase;
  --btn-font-family: #{var.$font-family-craftwork-grotesk};
  --btn-height: 75px;
  --btn-border-radius: 18px;
  --btn-border-width: 1px;
  --btn-bg-color: #{var.$liner-1};
  --btn-text-color: #{var.$deep-blue-color};
  --btn-disable-bg: #{var.$liner-1};
  --btn-font-weight: 400;
  --btn-border-color: #{var.$grass-green-color};
}

.btn-raised {
  @extend .btn;

  &.mat-mdc-raised-button[disabled][disabled] {
    background: var(--btn-disable-bg);
    opacity: var(--btn-disable-opacity);
    --mdc-protected-button-container-elevation: 0;
    --mdc-protected-button-disabled-label-text-color: #{--btn-text-color};
  }

  &.mat-mdc-raised-button {
    width: 100%;
    text-transform: var(--btn-text-transform);
    font-family: var(--btn-font-family);
    font-size: var(--btn-font-size);
    border-radius: var(--btn-border-radius);
    height: var(--btn-height);
    letter-spacing: 0;
    background: var(--btn-disable-bg);
    font-weight: var(--btn-font-weight);
    --mdc-protected-button-container-color: var(--btn-bg-color);
    --mdc-protected-button-label-text-color: var(--btn-text-color);
  }
}

.btn-raised-dark {
  @extend .btn;
  @extend .btn-raised;
  --btn-text-color: #{var.$white-color};
  --btn-disable-bg: #{var.$space-gray-color};
  --btn-bg-color: #{var.$space-gray-color};
}

.dashed-button {
  @extend .btn;
  --dashed-btn-border-size: 2px;
  --dashed-btn-border-color: #{var.$deep-blue-color};
  --dashed-btn-paddind: 25px 0;
  --dashe-btn-width: 100%;
  --dashed-btn-text-color: #{var.$deep-blue-color};
  --dashed-btn-text-font-size: 16px;
  --dashed-btn-border-radius: 18px;
  --dashed-btn-text-font-weight: 400;

  &.dashed-button {
    border-radius: var(--dashed-btn-border-radius);
    border: var(--dashed-btn-border-size) dashed var(--dashed-btn-border-color);
    width: var(--dashe-btn-width);
    padding: var(--dashed-btn-paddind);

    & > .mdc-button__label {
      color: var(--dashed-btn-text-color);
      font-size: var(--dashed-btn-text-font-size);
      font-weight: var(--dashed-btn-text-font-weight);
      letter-spacing: 0;
      word-spacing: 0;
    }
  }
}

.btn-stroked {
  @extend .btn;

  &.mat-mdc-outlined-button {
    border-radius: var(--btn-border-radius);
    height: var(--btn-height);

    &:not(:disabled) {
      border: var(--btn-border-width) solid var(--btn-border-color);
    }

    & > .mdc-button__label {
      color: var(--btn-text-color);
      font-size: var(--btn-font-size);
      text-transform: var(--btn-text-transform);
      font-weight: var(--btn-font-weight);
      letter-spacing: 0;
      word-spacing: 0;
    }
  }
}

.btn-basic {
  @extend .btn;
  --btn-color: #{var.$space-gray-50-color};

  &.mat-mdc-button:not(:disabled) {
    box-shadow: var(--btn-box-shadow);
    width: 100%;
    //text-transform: var(--btn-text-transform);
    font-family: var(--btn-font-family);
    font-size: var(--btn-font-size);
    height: var(--btn-height);
    letter-spacing: 0;
    font-weight: var(--btn-font-weight);

    & > .mdc-button__label {
      color: var(--btn-color) !important;
    }
  }
}
