//@media screen and (max-width: 768px) {
//  body {
//    background: #fff;
//  }
//}

@media (max-width: 768px) {
  .underlined-title {
    text-align: center;
    padding-bottom: 15px;

    &:after {
      left: calc(50% - 35px);
      right: calc(50% - 35px);
    }
  }
}
