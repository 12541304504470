@use 'apps/lk-egk/src/variables' as var;

.h1 {
  font-family: 'Craftwork Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  color: var.$deep-blue-color;

  &_bold {
    font-weight: 700;
  }

  &_bolder {
    font-weight: 900;
  }
}

.h2 {
  font-family: Craftwork Grotesk, 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: var.$deep-blue-color;
}

.h3 {
  font-family: Craftwork Grotesk, 'sans-serif';
  font-style: normal;
  font-size: 26px;
  line-height: 31px;
  color: var.$deep-blue-color;

  &_regular {
    font-weight: 500;
  }

  &_semi-bold {
    font-weight: 600;
  }
}

.h4 {
  font-family: Craftwork Grotesk, 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: var.$deep-blue-color;
  letter-spacing: 0;

  &_semi-bold {
    font-weight: 600;
  }
}

.h5 {
  font-family: Craftwork Grotesk, 'sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: var.$deep-blue-color;

  &_regular {
    font-weight: 500;
  }

  &_semi-bolb {
    font-weight: 600;
  }

  &_bold {
    font-weight: 700;
  }
}

.p1 {
  font-family: Manrope, 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var.$deep-blue-color;

  &_medium {
    font-weight: 500;
  }

  &_semi-bold {
    font-weight: 600;
  }

  &_bold {
    font-weight: 700;
  }

  &_small {
    font-weight: 300;
  }
}

.p2 {
  font-family: Manrope, 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var.$deep-blue-color;

  &_semi-bold {
    font-weight: 600;
  }

  &_medium {
    font-weight: 500;
  }
}

.p-small {
  font-family: Manrope, 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var.$deep-blue-color;

  &_bold {
    font-weight: 700;
  }
}

.link-text {
  font-family: Manrope, 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var.$space-gray-color;

  &_bold {
    font-weight: 700;
  }
}

.btn-text {
  font-family: Craftwork Grotesk, 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase;
}
