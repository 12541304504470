@font-face {
  font-family: Craftwork Grotesk;
  src: url('CraftworkGrotesk-Heavy.woff2');
  font-weight: 900;
}

@font-face {
  font-family: Craftwork Grotesk;
  src: url('CraftworkGrotesk-Bold.woff2');
  font-weight: 700;
}

@font-face {
  font-family: Craftwork Grotesk;
  src: url('CraftworkGrotesk-SemiBold.woff2');
  font-weight: 600;
}

@font-face {
  font-family: Craftwork Grotesk;
  src: url('CraftworkGrotesk-Medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: Craftwork Grotesk;
  src: url('CraftworkGrotesk-Regular.woff2');
  font-weight: 400;
}
