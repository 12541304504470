/* You can add global styles to this file, and also import other style files */
@use 'apps/lk-egk/src/variables' as var;
@import 'assets/fonts/inter-font/inter.font.scss';
@import 'assets/fonts/craftwork-grotesk/craft-grotesk.forn.scss';
@import 'assets/fonts/manrope/manrope.font.scss';
@import 'assets/styles/_text.scss';
@import "node_modules/keen-slider/keen-slider.min.css";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --body-bg: no-repeat center linear-gradient(323.11deg, #ffffff -3.75%, rgba(255, 255, 255, 0.08) 19.88%),
  linear-gradient(151.58deg, #ffffff 1.83%, rgba(255, 255, 255, 0.08) 34.5%),
  radial-gradient(134.9% 506.75% at -11.48% 67.46%, #cae32f 0%, rgba(255, 255, 240, 0.33) 75.73%), #fffff0;
  --mdc-theme-primary: #{var.$grass-green-color};
  --mat-option-selected-state-label-text-color: var(--mdc-theme-primary);
}

html,
body {
  min-height: 100%;
}

body {
  background: var(--body-bg);
  background-size: contain;

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    box-shadow: -15px 0px 34px -35px rgba(0, 0, 0, 0.75);

    &:hover {
      width: 10px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(180, 180, 180, 0.4);
  }
}

.body-cream {
  background: var.$cream-color;
}

.body-white {
  background: var.$white-color;
}

button {
  cursor: pointer;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.content-start {
  justify-content: flex-start;
}

.content-center {
  justify-content: center;
}

.direction-column {
  flex-direction: column;
}

.w-100 {
  width: 100% !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px;
}

.ml-35 {
  margin-left: 35px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.p-relative {
  position: relative !important;
}

.overflow-visible {
  overflow: visible !important;
}

.bs-none {
  box-shadow: none;
}

.underlined-title {
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 25px;

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    background: var.$grass-green-color;
    height: 2px;
    width: 70px;
  }

  &_centered {
    text-align: center;

    &:after {
      left: calc(50% - 35px);
      right: calc(50% - 35px);
    }
  }

  @media screen and (max-width: 640px) {
    padding-bottom: 5px;
  }
}

.underlined-title.centered {
  text-align: center;

  &:after {
    bottom: -10px;
    left: 45%;
  }
}

.divisor {
  margin: 20px 0 45px;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #6fcf97;
}

.switch-area {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 1em 0;
  margin: 1em 0;

  &_dashed {
    border: 1px dashed var.$space-gray-50-color;
  }

  &__label {
    color: var.$space-gray-color;

    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }

  &__label.chosen {
    color: var.$grass-green-color;
    font-weight: 500;
  }
}

.switchArea {
  @media (max-width: 600px) {
    padding: 1em 10px;

    span {
      font-size: 12px;
    }
  }
}

.tel-link {
  text-decoration: none;
  color: #000;
}

.check-icon {
  color: var.$control-border-success;
  align-self: flex-end;
}

.mat-mdc-select-arrow svg {
  fill: #97ca3e !important;
}

.form-select {
  &_with-icon {
    & > .mat-mdc-text-field-wrapper {
      & > .mat-mdc-form-field-flex {
        & > .mat-mdc-form-field-infix {
          display: flex;

          & > mat-icon {
            margin-right: 15px;
          }
        }
      }
    }
  }

  &_without-label {
    &.mat-mdc-form-field-type-mat-select {
      & > .mat-mdc-text-field-wrapper {
        padding: 0 15px !important;
      }
    }
  }

  &.mat-mdc-form-field-type-mat-select {
    & > .mat-mdc-text-field-wrapper {
      padding: var(--control-padding);

      & > .mat-mdc-form-field-flex {
        & > .mat-mdc-form-field-infix {
          & > .mat-mdc-select {
            & > .mat-mdc-select-trigger {
              & > .mat-mdc-select-value {
                & > .mat-mdc-select-value-text {
                  font-weight: 600;
                  font-size: var(--control-label-focused-font-size) !important;
                }
              }
            }
          }

          & > label {
            padding-bottom: 5px;
          }
        }
      }
    }
  }

  & > .mat-mdc-select-value-text {
    font-size: var(--control-label-focused-font-size) !important;
    font-weight: 600;
  }

  & > .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-bottom: 0;
  }

  mat-label {
    font-size: var(--control-labe-font-size) !important;
  }

  @extend .form-control;
}

.form-control {
  --control-border-radius: #{var.$control-border-radius};
  --control-border-success: #{var.$control-border-success};
  --control-padding: #{var.$control-padding};
  --control-border-size: #{var.$control-border-size};
  --control-labe-font-size: 16px;
  --control-label-font-weight: #{var.$control-label-font-weight};
  --control-label-text-color: #{var.$control-text-color};
  --control-border-color-default: #{var.$control-border-main};
  --control-empty-bg: #{var.$control-empty-bg};
  --control-filled-bg: #{var.$control-fill-bg};
  --control-outline-color-success: rgba(32, 134, 72, 0.2);
  --control-outline-color-error: rgba(235, 87, 87, 0.2);
  --control-max-width: auto;
  --control-label-padding-bottom: 3px;
  --control-width: 100%;
  --control-font-family: #{var.$font-family-manrope};
  --control-label-focused-font-size: 16px;
  --control-input-font-weight: 700;
  --mdc-filled-text-field-focus-label-text-color: var(--control-label-text-color);
  --mdc-filled-text-field-label-text-color: var(--control-label-text-color);
  --mdc-filled-text-field-container-color: var(--control-filled-bg);
  padding-bottom: 20px;

  .mat-mdc-form-field-icon-prefix > .mat-icon, .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0;
    padding-right: 5px;
  }

  &.mat-mdc-form-field {
    width: 100%;
    max-width: var(--control-max-width);
  }

  &_green-border {
    & > .mat-mdc-text-field-wrapper {
      border-color: var(--control-border-success) !important;
    }
  }

  &_yellow-border {
    & > .mat-mdc-text-field-wrapper {
      border-color: #f2c94c !important;
    }
  }

  &.ng-touched.ng-invalid {
    & > .mat-mdc-text-field-wrapper {
      border: var(--control-border-size) solid var.$control-border-error;
    }
  }

  &.ng-touched.ng-invalid.mat-focused {
    & > .mat-mdc-text-field-wrapper {
      outline: 5px solid var(--control-outline-color-error);
    }
  }

  mat-error {
    margin-top: 5px;
    color: var.$error-color;
  }

  &.ng-valid.ng-touched {
    & > .mat-mdc-text-field-wrapper {
      border: var(--control-border-size) solid var(--control-border-success);
    }
  }

  &.ng-valid.ng-touched.mat-focused {
    & > .mat-mdc-text-field-wrapper {
      outline: 5px solid var(--control-outline-color-success);
    }
  }

  &.mat-form-field-hide-placeholder {
    & > .mat-mdc-text-field-wrapper {
      background-color: var(--control-empty-bg);
    }
  }

  &.mat-mdc-form-field-type-mat-input {
    & > .mat-mdc-text-field-wrapper {
      padding: var(--control-padding);
    }
  }

  & > .mat-mdc-text-field-wrapper {
    border-radius: var(--control-border-radius);
    border: var(--control-border-size) solid var(--control-border-color-default);
    background-color: var(--control-filled-bg);
    flex: none;

    & > .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }

    & > .mat-mdc-form-field-flex {
      & > .mat-mdc-form-field-infix {
        width: 100%;

        & mat-label {
          font-size: var(--control-labe-font-size);
        }

        & > .mdc-floating-label &:not(.mdc-floating-label--float-above) & > mat-label {
          font-size: var(--control-labe-font-size);
          font-weight: var(--control-label-font-weight);
          color: var(--control-label-text-color) !important;
          padding-bottom: var(--control-label-padding-bottom);
        }

        & > .mdc-floating-label--float-above & > mat-label {
          color: var(--control-label-text-color) !important;
          font-weight: var(--control-label-font-weight);
          font-size: var(--control-labe-font-size);
        }

        & > .mat-mdc-form-field-input-control {
          font-weight: var(--control-input-font-weight);
          font-family: var.$font-family-manrope;
          font-size: var(--control-labe-font-size);
        }

        & > .mdc-text-field .mdc-text-field__input {
          caret-color: #97ca3e;
        }
      }
    }

    & > .mat-form-field-ripple {
      background-color: transparent;
    }

    & > .mdc-line-ripple {
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }

  & > .mdc-text-field--focused {
    &:not(.mdc-text-field--disabled) .mdc-floating-label {
      color: var(--control-label-text-color);
      font-weight: var(--control-label-font-weight);
      font-size: var(--control-label-focused-font-size);
    }
  }

  & > .mdc-text-field--filled {
    .mdc-floating-label--float-above {
      transform: translateY(-100%) scale(1);
      padding-bottom: var(--control-label-padding-bottom);
      font-size: var(--control-labe-font-size);
    }
  }

  .mat-mdc-form-field-bottom-align::before {
    display: none;
    height: 0;
  }

  & > .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    position: relative;
  }
}

.checkbox {
  --mdc-checkbox-state-layer-size: 18px;
  margin-bottom: 5px;

  &.mat-mdc-checkbox {
    & > .mdc-form-field {
      & > .mdc-checkbox__ripple {
        width: 20px;
        height: 20px;
      }

      & > .mdc-checkbox {
        padding: calc((var(--mdc-checkbox-state-layer-size, 30px) - 28px) / 2);
        margin: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-state-layer-size, 37px)) / 2);
      }
    }
  }

  &.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: #{var.$grass-green-color};
  }

  label {
    margin-left: 5px !important;
    font-size: 13px;
  }

  &.mat-mdc-checkbox .mdc-checkbox {
    & > .mat-mdc-checkbox-touch-target {
      width: 0;
      height: 0;
    }
  }

  &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {

  }

  &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  }

  &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    --mdc-checkbox-selected-icon-color: #{var.$grass-green-color};
    --mdc-theme-secondary: #{var.$grass-green-color};
    --mdc-checkbox-selected-hover-icon-color: #{var.$grass-green-color};
    --mdc-checkbox-selected-presed-icon-color: #{var.$grass-green-color};
    --mdc-checkbox-selected-focus-icon-color: #{var.$grass-green-color};
    --mdc-checkbox-selected-pressed-icon-color: #{var.$grass-green-color};
  }
}

.remind-password-stepper {
  background: transparent;

  .stepper-disabled {
    & > .mat-horizontal-stepper-wrapper {
      & > .mat-horizontal-stepper-header-container {
        & > mat-step-header {
          pointer-events: none !important;
        }
      }
    }
  }

  &.mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: var.$grass-green-color !important;
  }

  & > .mat-horizontal-stepper-wrapper {
    & > .mat-horizontal-content-container {
      padding: 8px;
    }

    & > .mat-horizontal-stepper-header-container {
      align-self: center;

      & > .mat-stepper-horizontal-line {
        border-top: 2px dotted #999999;
        margin: 0 -25px -30px -35px;
        width: 135px;
      }

      & > .mat-step-header {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 8px;

        .mat-step-icon-selected {
          background-color: var.$grass-green-color;
        }

        .mat-step-icon-state-done {
          mat-icon {
            height: 24px;
            width: 24px;
            margin-top: 0;
          }
        }

        mat-icon {
          height: 32px;
          width: 32px;
          margin-top: 8px;
        }

        & > .mat-step-label {
          margin-bottom: 10px;
          color: var.$grass-green-color;
        }
      }
    }
  }
}

.custom-slider {
  &.mat-mdc-slider {
    & > .mdc-slider__track {
      height: 7px;
      background: var.$slider-track-bg;
      box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;

      & > .mdc-slider__track--inactive {
        height: var(--mdc-slider-inactive-track-height, 7px);
        background-color: var.$slider-track-bg;
      }

      & > .mdc-slider__track--active {
        height: var(--mdc-slider-active-track-height, 7px);

        & > .mdc-slider__track--active_fill {
          border-top: 7px solid var.$grass-green-color;
        }
      }
    }

    & > .mdc-slider__thumb {
      width: 34px;
      height: 34px;
      top: 6px;
      border-radius: 50%;

      & > .mat-ripple {
        border-radius: 50%;
      }
    }
  }

  & > .mat-mdc-slider-visual-thumb & > .mat-ripple {
    border-radius: 50%;
  }

  & > .mdc-slider__thumb {
    & > .mdc-slider__thumb-knob {
      width: 30px;
      height: 30px;
      border: 3px solid #fff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);

      &:after {
        content: '';
        display: block;
        position: absolute;
        background: no-repeat url('~apps/lk-egk/src/assets/image/icons/slider-icon.svg');
        width: 12px;
        height: 10px;
        top: 7px;
        left: 6px;
      }
    }
  }

  &.mat-mdc-slider.mat-primary {
    --mdc-slider-handle-color: #{var.$grass-green-color};
    --mdc-slider-focus-handle-color: #{var.$grass-green-color};
    --mdc-slider-hover-handle-color: #{var.$grass-green-color};
  }
}

.card {
  //--card-bg-image: url("/assets/image/icons/background-logo.png");
  --card-bg-color: #fff;

  background: var(--card-bg-color) var(--card-bg-image) no-repeat left 0 bottom -45px;
  background-size: auto, auto, contain;

  &.mat-mdc-card {
    border-radius: 20px;
    border: none;
  }
}

.promo-card {
  padding: 0 50px 50px;
  border: none;
  box-shadow: none;

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    box-shadow: -15px 0px 34px -35px rgba(0, 0, 0, 0.75);

    &:hover {
      width: 10px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(180, 180, 180, 0.4);
  }
}

.form-slide-toggle {
  &.mat-mdc-slide-toggle {
    --mdc-switch-unselected-handle-color: #{var.$space-gray-color};
    --mdc-switch-unselected-pressed-handle-color: #{var.$space-gray-color};
    --mdc-switch-unselected-focus-handle-color: #{var.$space-gray-color};
    --mdc-switch-unselected-hover-handle-color: #{var.$space-gray-color};

    .mdc-switch {
      width: 40px;
      height: 20px;

      .mdc-switch__track {
        height: 20px;
        border-radius: 16px;
        box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.35);
        background-color: #f5f5f5;

        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      & > .mdc-switch__handle-track {
        & > .mdc-switch__handle {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 4px solid #fff;
          background-color: var.$control-border-success;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);

          & > .mdc-switch__ripple {
            width: 35px;
            height: 35px;
          }

          & > .mdc-switch__icons {
            display: none;
          }
        }
      }
    }

    &.mdc-switch__icons {
      display: none;
    }
  }

  &.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: none;
    --mdc-switch-selected-handle-color: #{var.$grass-green-color};
    --mdc-switch-selected-hover-state-layer-color: #{var.$grass-green-color};
    --mdc-switch-selected-pressed-state-layer-color: #{var.$grass-green-color};
    --mdc-switch-selected-focus-handle-color: #{var.$grass-green-color};
    --mdc-switch-selected-hover-handle-color: #{var.$grass-green-color};
    --mdc-switch-selected-pressed-handle-color: #{var.$grass-green-color};
    --mdc-switch-selected-focus-track-color: #{var.$grass-green-color};
    --mdc-switch-selected-hover-track-color: #{var.$grass-green-color};
    --mdc-switch-selected-pressed-track-color: #{var.$grass-green-color};
    --mdc-switch-selected-track-color: none;
  }
}

.mdc-dialog__surface {
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.discount-modal {
  @extend .dialog-modal;

  & > .mat-mdc-dialog-container {
    position: relative;

    &:after {
      content: '';
      background: url('~apps/lk-egk/src/assets/image/icons/increase-credit-promo.png');
      display: block;
      position: absolute;
      top: -112px;
      left: calc((100% / 2) - 80px);
      width: 160px;
      height: 160px;
      z-index: 100;
    }

    & > .mdc-dialog__container {
      & > .mat-mdc-dialog-surface {
        border-radius: 30px;
        background: transparent !important;
        overflow: hidden !important;
      }
    }
  }
}

.tg-modal {
  @extend .dialog-modal;

  & > .mat-mdc-dialog-container {
    position: relative;

    &:after {
      content: '';
      background: url('~apps/lk-egk/src/assets/image/icons/telegram-modal-icon.svg');
      display: block;
      position: absolute;
      top: -112px;
      left: calc((100% / 2) - 80px);
      width: 160px;
      height: 160px;
      z-index: 100;
    }

    & > .mdc-dialog__container {
      & > .mat-mdc-dialog-surface {
        border-radius: 30px;
        background: transparent !important;
        overflow: hidden !important;
      }
    }
  }
}

.dialog-modal {
  & > .mat-mdc-dialog-container {
    width: 100%;
    padding: 15px;

    & > .mdc-dialog__container {
      & > .mat-mdc-dialog-surface {
        border-radius: 30px;
        background: #fff;
      }
    }
  }
}

.bank-id-block {
  display: flex;
  justify-content: center;

  &__img {
    width: 100%;
    cursor: pointer;
    opacity: 1;

    &:hover {
      opacity: 0.6;
      transition: opacity linear 0.6s;
    }

    &__disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.modal-breakpoints {
  @media screen and (max-width: 640px) {
    .card {
      padding: 0 15px 20px;
    }

    .form {
      padding: 0;
    }

    .actions {
      gap: 5px;

      button {
        height: 45px;
      }
    }

    .btn-raised {
      --btn-font-size: 12px;
    }

    .btn-stroked {
      --btn-font-size: 12px;
    }

    .label {
      &.p1 {
        font-size: 13px;
        margin-bottom: 5px;
      }
    }

    .title {
      margin-bottom: 15px;
    }

    .form-control {
      --control-border-radius: 30px;

      &.mb-40 {
        margin-bottom: 20px;
      }
    }
  }

}

.credit-card-option {
  width: 100%;

  &.mat-mdc-option {
    width: 100%;

    &.mdc-list-item--disabled {
      opacity: 1;
    }

    & > span {
      width: 100%;
    }
  }
}

.profile-tab {
  margin-top: 50px;
  width: 100%;

  .mat-mdc-tab.mdc-tab {
    height: auto;
    padding: 0 10px 0 20px;
    margin: 0;
    min-width: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      content: '';
      display: block;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      position: absolute;
      background-color: rgba(179, 187, 196, 1);
      top: -3px;
      bottom: 0;
      left: 0;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }

  .mat-mdc-tab-body-content {
    display: flex;
  }

  .mat-mdc-tab-labels {
    margin-left: 75px;
  }

  .mdc-tab-indicator {
    display: none;
  }

  .mdc-tab__ripple {
    display: none;
  }

  .mdc-tab__content {
    .mdc-tab__text-label {
      color: rgba(179, 187, 196, 1);
    }
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: var.$deep-blue-color;
  }

  @media screen and (max-width: 1230px) {
    .mat-mdc-tab-labels {
      margin-left: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 10px 10px;
      justify-content: center;
    }
  }

  @media screen and (max-width: 768px) {
    .mat-mdc-tab-header {
      display: none;
    }
  }
}

.card-wrap {
  border: 1px solid var.$grass-green-color;
  border-radius: 50px;
  padding: 30px;
  max-width: 1100px;
  width: 100%;
}

.credit-cards {
  display: flex;
  //flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  gap: 27px;
}

.input-error {
  transition: all linear 0.3s;
  margin-top: 10px;
  color: red;
  font-size: 10px;
}

// Todo: Need to refactor
.credit-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0;
  width: 100%;
  gap: 5px;

  &_verified {
    color: var.$grass-green-color;
  }

  &_not-verified {
    color: var.$error-color;
  }

  &__verified-icon {
    width: 10px !important;
  }

  &__not-verified-icon {
    width: 15px !important;
  }

  &__label {
    font-size: 14px;
    font-weight: 400;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &-label {
      font-size: 14px;
    }

    &_not-verified {
      cursor: pointer;

      span,
      mat-icon {
        opacity: 1;
        transition: opacity linear 0.3s;

        &:hover {
          opacity: 0.6;
          transition: opacity linear 0.3s;
        }
      }
    }
  }
}

.control-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.error-block {
  font-size: 12px;
  font-family: var.$font-family-manrope;
  color: var.$error-color;
}

.decision-icon {
  width: 100%;
  height: 100%;
  max-width: 190px;
  min-height: 190px;
  margin: 0 auto 25px;
  border-radius: 50%;
  border: 1px solid var.$grass-green-color;
  padding: 34px;

  &__wrap {
    padding: 22px;
    background: rgba(251, 251, 251, 1);
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.link {
  --link-font-weight: 400;
  --link-font-size: 14px;
  --link-line-height: 18px;
  --link-color: #{var.$deep-blue-color};
  --link-cursor: pointer;
  --link-border-size: 1px;
  --link-border-color: #{var.$deep-blue-color};

  font-family: var.$font-family-manrope;
  font-style: normal;
  font-weight: var(--link-font-weight);
  font-size: var(--link-font-size);
  line-height: var(--link-line-height);
  color: var(--link-color);
  cursor: var(--link-cursor);
  text-decoration: none;

  &-underline {
    border-bottom: 1px solid var(--link-border-color);
  }

  &-dashed {
    padding: 5px;
    border-bottom: var(--link-border-size) dashed var(--link-border-color);
  }
}

.green-link {
  --link-color: #{var.$grass-green-color};
  --link-font-weight: 700;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px;

  & button {
    outline: none;
    background: none;
    border: none;
    opacity: 1;
    transition: opacity linear 0.3s;

    &:hover {
      opacity: 0.7;
      transition: opacity linear 0.3s;
    }

    & > mat-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.auth-card {
  padding: 10px 40px 35px;
  box-shadow: none;
  border: 5px solid mix(#f6f8fa, #f7fafc);
  margin-bottom: 90px;
  border-radius: 30px;

  @media (max-width: 768px) {
    .link {
      --link-font-size: 14px;
    }

    .mb-40 {
      margin-bottom: 20px;
    }
    .p-small {
      font-size: 12px;
    }
    .change-phone {
      font-size: 12px;
    }
  }
}

.mr-10 {
  margin-right: 10px !important;
}

::ng-deep.mat-mdc-select-panel.mat-pseudo-checkbox.mdc-list-item {
  visibility: hidden !important;
}

@import 'assets/styles/responsive.scss';

.ngx-slider {
  margin: 20px 0 !important;
}

.ngx-slider .ngx-slider-pointer:after {
  content: '';
  display: block !important;
  position: absolute !important;
  background: no-repeat url('~apps/lk-egk/src/assets/image/icons/slider-icon.svg') !important;
  width: 12px !important;
  height: 10px !important;
  top: 6px !important;
  left: 5px !important;
  border-radius: 0 !important;
}

.ngx-slider .ngx-slider-pointer {
  -webkit-appearance: none;
  appearance: none;
  height: 28px !important;
  width: 28px !important;
  background: var.$grass-green-color !important;
  border: 3px solid #fff !important;
  border-radius: 50% !important;
  top: -12px !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  outline: none !important;
  //-webkit-clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%) !important;
  //clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%) !important;
}

.ngx-slider .ngx-slider-bar {
  height: 7px !important;
  border-radius: 4px !important;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.25) !important;
  background: #E4EAE2 !important;
}

.ngx-slider .ngx-slider-selection {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25) !important;
  background: var.$grass-green-color !important;
}

.ngx-slider .ngx-slider-tick-value {
  display: none !important;
}

.ngx-slider .ngx-slider-tick {
  margin-left: 11px !important;
  background: #2B2B2B !important;
  width: 9px !important;
  height: 9px !important;
  top: 2px !important;

  &:first-child {
    margin-left: 5px !important;
  }
}

.base-hint {
  position: absolute;
  bottom: 5px;
}

.promo-code-hint {
  @extend .base-hint;
  right: 0;
  max-width: 540px;
  width: 100%;

  & > .hint {
    --hint-max-width: 540px;
    --hint-border-radius: 20px;
    --hint-padding: 10px;
    --hint-bg-color: #fffafa;
    --hint-error-color: #eb5757;
    --hint-content-color: rgb(25, 62, 83);
    --hint-before-position: 90%;
  }
}


.payment-hint {
  position: relative;
  display: block;
  margin-bottom: 15px;

  & > .hint {
    --hint-max-width: 100%;
    --hint-border-radius: 12px;
    --hint-padding: 15px;
  }
}

.can-create-loan {
  & > .loan-error-block {
    margin: 0 auto;
    --loan-error-block-border-radius: 20px;
    --loan-error-block-border: 1px solid rgba(235, 87, 87, 0.2);
    --loan-error-block-padding: 15px;
    --loan-error-block-content-background: #{var.$white-color};
    --loan-error-block-content-border: 1px solid #{var.$error-color};
    --loan-error-block-title-color: #{var.$error-color};
    --loan-error-block-title-font-weight: 400;
  }
}

.payment-hint {
  position: absolute;
  width: 100%;
  z-index: 100;
  top: -100px;


  & > .hint {
    --hint-max-width: 100%;
    --hint-border-radius: 20px;
    --hint-padding: 10px;
    --hint-bg-color: rgba(255, 249, 249);

    --hint-success-color: #{var.$grass-green-color};
    --hint-success-bg-color: rgba(255, 249, 249);
    --hint-success-content-title-color: #{var.$grass-green-color};
    --hint-success-content-color: #{var.$deep-blue-color};

    --hint-pending-color: #f2c94c;
    --hint-pending-bg-color: rgba(255, 249, 249);
    --hint-pending-content-title-color: #f2c94c;
    --hint-pending-content-color: #{var.$deep-blue-color};

    --hint-error-color: #EB5757;
    --hint-error-content-color: #EB5757;
    --hint-error-content-title-color: #EB5757;


    --hint-before-position: 50%;
    --hint-content-title-mb: 0;
  }
}

.mat-expansion-panel-header.mat-expanded {
  height: auto !important;
}

.mat-mdc-option.mdc-list-item--disabled {
  pointer-events: auto !important;
  opacity: .38
}

.mdc-list-item__primary-text {
  opacity: 1 !important;
}

.color-green {
  color: var.$grass-green-color !important;
}

.bank-id-register-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 15px;
  border: 1px solid #F0F2F4;
  outline: none;
  border-radius: 18px;
  padding: 0 10px;

  &__text {
    font-size: 18px;
    font-weight: 100;
    color: #193E53;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var.$grass-green-color;
  border-radius: 20px;
  overflow: hidden;
}
