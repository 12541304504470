$login-bnt-bgc: #208648;
$login-btn-disable-bgc: rgba(32, 134, 72, 0.8);

$verify-info-text: #808285;
$button-text: #bdbdbd;

$credit-control-limits: #999999;

// Slider variables
$slider-track-active-fill: #208648;
$slider-track-bg: #e4eae2;
$slider-handle-color: #208648;

// FORM CONTROL
$control-border-main: #e4eae2;
$control-border-success: #97ca3e;
$control-border-error: #eb5757;
$control-empty-bg: #f5f5f5;
$control-fill-bg: #ffffff;
$control-text-color: #667788;
$control-padding: 6px 24px;
$control-border-radius: 18px;
$control-border-size: 2px;
$control-label-font-size: 18px;
$control-label-font-weight: 400;

// COLORS
$white-color: #ffffff;
$error-color: #eb5757;
$black-color: #2b2b2b;
$deep-blue-color: rgba(25, 62, 83, 1);
$space-gray-color: #667788;
$space-gray-50-color: #b3bbc4;
$space-gray-10-color: #b3bbc4;
$grass-green-color: #97ca3e;
$lime-color: #cae32f;
$yellow-color: #f2c94c;
$cream-color: #ffffe6;
$bg-01-color: #ffff22;
$bg-02-color: #fffff0;
$liner-1: linear-gradient(90deg, #97ca3e -14.88%, #ffff22 116.14%);
$liner-bg: linear-gradient(323.11deg, #ffffff -3.75%, rgba(255, 255, 255, 0.08) 19.88%),
  linear-gradient(151.58deg, #ffffff 1.83%, rgba(255, 255, 255, 0.08) 34.5%),
  radial-gradient(134.9% 506.75% at -11.48% 67.46%, #cae32f 0%, rgba(255, 255, 240, 0.33) 75.73%), #fffff0;

// FONT FAMILY
$font-family-craftwork-grotesk: Craftwork Grotesk, 'sans-serif';
$font-family-manrope: Manrope, 'sans-serif';
