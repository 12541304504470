@font-face {
  font-family: Inter;
  src: url('Inter-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: Inter;
  src: url('Inter-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url('Inter-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url('Inter-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url('Inter-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url('Inter-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url('Inter-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url('Inter-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url('Inter-Thin.ttf');
  font-weight: 100;
}
